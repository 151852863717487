import { AppBar, Container, Toolbar, Typography } from "@mui/material";
import Home from "./Home";

export default function Navbar() {
    return (
        <div className="d-flex flex-column">
            <AppBar position="static">
                <Container maxWidth={"100%"}>
                    <Toolbar disableGutters>
                        <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="#app-bar-with-responsive-menu"
                            sx={{
                                mr: 2,
                                display: 'flex',
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            Sauron's Goats
                        </Typography>
                    </Toolbar>
                </Container>
            </AppBar>

            <Home />
        </div>
    );
}