import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { BACKEND_URL } from "../constants/urls";
import { Button, Checkbox, FormControlLabel, TextField, Tooltip } from "@mui/material";
import TopLoader from "react-top-loader";
import DataTable from "react-data-table-component";
import AddOrgDialog from "./AddOrgDialog";

export default function Home() {

    const [orgs, setOrgs] = useState([]);
    const [orgsToDisplay, setOrgsToDisplay] = useState([]);
    const [search, setSearch] = useState("");
    const [showAddOrg, setShowAddOrg] = useState(false);

    const getOrgs = useQuery("orgs", async () => {
        let resp = await axios.get(`${BACKEND_URL}/auth/v1/admin/getOrganisations`, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("sauron-goat-token")}`
            }
        });
        return resp.data;
    },
        {
            enabled: false,
            onSuccess: (data) => {
                if (data.status) {
                    setOrgs(data.data);
                }
                else {
                    alert(data.message);
                }
            },
            onError: (error) => {
                alert(error.message);
            }
        });

    const orgMutation = useMutation(async (data) => {
        let resp = await axios.put(`${BACKEND_URL}/auth/v1/admin/updateOrganisation/${data.id}`, data.body, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("sauron-goat-token")}`
            }
        });
        return resp.data;
    }, {
        onSuccess: (data) => {
            if (data.status) {
                getOrgs.refetch({ cancelRefetch: true });
            }
            else {
                alert(data.message);
            }
        },
        onError: (error) => {
            alert(error.message);
        }
    });

    const orgStatusMutation = useMutation(async (data) => {
        let resp = await axios.post(`${BACKEND_URL}/auth/v1/admin/updateOrganisationStatus/${data.id}`, data.body, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("sauron-goat-token")}`
            }
        });
        return resp.data;
    }, {
        onSuccess: (data) => {
            if (data.status) {
                getOrgs.refetch({ cancelRefetch: true });
                alert(data.message);
            }
            else {
                alert(data.message);
            }
        },
        onError: (error) => {
            alert(error.message);
        }
    });

    const handleOrgEdit = (data) => {
        let body = {
            autoTripStartEnabled: data.autoTripStartEnabled,
            autoLeaveApprove: data.autoLeaveApprove,
            isOrderModuleActivated: data.isOrderModuleActivated,
            isTallyIntegrationActivated: data.isTallyIntegrationActivated,
            isTrial: data.isTrial,
            isRouteRequiredWhenStartingTrip: data.isRouteRequiredWhenStartingTrip,
            restrictEmployeeToSeeOthersDealers: data.restrictEmployeeToSeeOthersDealers,
            maxUserLimit: data.maxUserLimit
        }

        orgMutation.mutate({ id: data.id, body: body });
    }

    const handleOrgStatusChange = (data) => {
        let body = {
            isActive: data.isActive
        }

        orgStatusMutation.mutate({ id: data.id, body: body });
    }

    useEffect(() => {
        let filteredOrgs = orgs.filter((org) => {
            if (org?.name?.toLowerCase().includes(search?.toLowerCase())) return true;
            if (org?.contactPerson?.toLowerCase().includes(search?.toLowerCase())) return true;
            if (org?.phoneNumber?.toString()?.toLowerCase().includes(search?.toLowerCase())) return true;
            if (org?.address?.toLowerCase().includes(search?.toLowerCase())) return true;
            if (org?.email?.toLowerCase().includes(search?.toLowerCase())) return true;
            if (org?.gstin?.toString()?.toLowerCase().includes(search?.toLowerCase())) return true;
            return false;
        })

        setOrgsToDisplay(filteredOrgs);
    }, [search, orgs])

    useEffect(() => {
        getOrgs.refetch({ cancelRefetch: true });
    }, [])

    const columns = [
        {
            name: "Name",
            selector: "name",
            width: "30%",
            cell: (row) => <div>
                <Tooltip title={row?.name}>
                    <span>{row?.name}</span>
                </Tooltip>
            </div>,
        },
        {
            name: "Contact Person",
            selector: "contactPerson",
            cell: (row) => <div>
                <Tooltip title={row?.contactPerson}>
                    <span>{row?.contactPerson}</span>
                </Tooltip>
            </div>,
        },
        {
            name: "Phone Number",
            selector: "phoneNumber",
            cell: (row) => <div>
                <Tooltip title={row?.phoneNumber}>
                    <span>{row?.phoneNumber}</span>
                </Tooltip>
            </div>,
        },
        {
            name: "Address",
            selector: "address",
            cell: (row) => <div>
                <Tooltip title={row?.address}>
                    <span>{row?.address}</span>
                </Tooltip>
            </div>,
        },
        {
            name: "Email",
            selector: "email",
            cell: (row) => <div>
                <Tooltip title={row?.email}>
                    <span>{row?.email}</span>
                </Tooltip>
            </div>,
        },
        {
            name: "gstin",
            selector: "gstin",
            cell: (row) => <div>
                <Tooltip title={row?.gstin}>
                    <span>{row?.gstin}</span>
                </Tooltip>
            </div>,
        }
    ]

    const ExpandedComponent = ({ data }) => {
        const [orgData, setOrgData] = useState(data);
        return (
            <div className="d-flex flex-column">
                <div className="d-flex flex-wrap p-3" style={{ columnGap: "1rem", rowGap: "1rem" }}>
                    <FormControlLabel control={<Checkbox checked={orgData?.autoTripStartEnabled ? true : false} onClick={(e) => setOrgData({ ...orgData, autoTripStartEnabled: e.target.checked })} />} label="Auto Trip Start" name="autoTripStartEnabled" />
                    <FormControlLabel control={<Checkbox checked={orgData?.autoLeaveApprove ? true : false} onClick={(e) => setOrgData({ ...orgData, autoLeaveApprove: e.target.checked })} />} label="Auto Leave Approve" />
                    <FormControlLabel control={<Checkbox checked={orgData?.isOrderModuleActivated ? true : false} onClick={(e) => setOrgData({ ...orgData, isOrderModuleActivated: e.target.checked })} />} label="Order Module Activated" />
                    <FormControlLabel control={<Checkbox checked={orgData?.isTallyIntegrationActivated ? true : false} onClick={(e) => setOrgData({ ...orgData, isTallyIntegrationActivated: e.target.checked })} />} label="Tally Integration" />
                    <FormControlLabel control={<Checkbox checked={orgData?.isTrial ? true : false} onClick={(e) => setOrgData({ ...orgData, isTrial: e.target.checked })} />} label="Is Trial" />
                    <FormControlLabel control={<Checkbox checked={orgData?.isRouteRequiredWhenStartingTrip ? true : false} onClick={(e) => setOrgData({ ...orgData, isRouteRequiredWhenStartingTrip: e.target.checked })} />} label="Is Route Required When Starting Trip" />
                    <FormControlLabel control={<Checkbox checked={orgData?.restrictEmployeeToSeeOthersDealers ? true : false} onClick={(e) => setOrgData({ ...orgData, restrictEmployeeToSeeOthersDealers: e.target.checked })} />} label="Restrict Employee To See Others Dealers" />
                    <FormControlLabel control={<TextField label="Max Users" value={orgData?.maxUserLimit} onChange={(e) => setOrgData({ ...orgData, maxUserLimit: e.target.value })} />}/>
                </div>
                <div className="d-flex align-items-center justify-content-end mb-4">
                    <Button variant="contained" color={`${orgData.isActive ? "error" : "success"}`} className="mt-3 align-self-end me-5" onClick={(e) => {
                        handleOrgStatusChange(orgData?.isActive ? { id: orgData.id, isActive: false } : { id: orgData.id, isActive: true });
                    }}>{orgData.isActive ? "Deactivate" : "Activate"}</Button>
                    <Button variant="contained" color="primary" className="mt-3 align-self-end me-5" type="submit" onClick={(e) => {
                        e.preventDefault();

                        let data = {
                            id: orgData.id,
                            autoTripStartEnabled: orgData?.autoTripStartEnabled ? true : false,
                            autoLeaveApprove: orgData?.autoLeaveApprove ? true : false,
                            isOrderModuleActivated: orgData?.isOrderModuleActivated ? true : false,
                            isTallyIntegrationActivated: orgData?.isTallyIntegrationActivated ? true : false,
                            isTrial: orgData?.isTrial ? true : false,
                            isRouteRequiredWhenStartingTrip: orgData?.isRouteRequiredWhenStartingTrip ? true : false,
                            restrictEmployeeToSeeOthersDealers: orgData?.restrictEmployeeToSeeOthersDealers ? true : false,
                            maxUserLimit: orgData?.maxUserLimit ? parseFloat(orgData?.maxUserLimit) : 0
                        }

                        handleOrgEdit(data);
                    }}>Save</Button>
                </div>
            </div>
        )

    }

    return (
        <div className="mt-3">
            <h3 className="px-5">Organisations</h3>

            {(getOrgs.isFetching || getOrgs.isLoading || orgMutation.isLoading) && <div style={{ padding: 16 }}>
                <TopLoader backgroundColor="#eee6ff" show fixed={true} color="#ea6a12" />
            </div>}

            <div className="mt-3 d-flex justify-content-between px-5 align-items-center">
                <TextField label="Search" placeholder="Search" variant="standard" onChange={(e) => setSearch(e.target.value)} />

                <Button variant="contained" color="primary" onClick={() => setShowAddOrg(true)}>Add Org</Button>
            </div>

            {
                (!getOrgs.isFetching && !getOrgs.isLoading) && <DataTable
                    columns={columns}
                    data={orgsToDisplay}
                    expandableRows
                    expandableRowsComponent={ExpandedComponent}
                    pagination={true}
                />
            }

            {showAddOrg && <AddOrgDialog open={showAddOrg} close={() => setShowAddOrg(false)} refetchOrgs={() => getOrgs.refetch({ cancelRefetch: true })} />}

        </div>
    )

}