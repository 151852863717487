
import './App.css';
import Navbar from './components/Navbar';
import { useEffect, useState } from 'react';
import axios from 'axios';
import LoginDialog from './components/LoginDialog';

function App() {

  const [showLogin, setShowLogin] = useState(false);

  useEffect(() => {
    let token = window.localStorage.getItem("sauron-goat-token");

    if(!token) {
      setShowLogin(true);
    }

  }, [])

  

  return (
    <>
    { !showLogin &&
      <Navbar />
    }
      <LoginDialog open={showLogin} close={() => setShowLogin(false)} />
    </>
  );
}

export default App;
