import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { useState } from "react";
import { useMutation } from "react-query";
import { BACKEND_URL } from "../constants/urls";
import TopLoader from "react-top-loader";
import axios from "axios";


export default function AddOrgDialog(props) {

    const [orgData, setOrgData] = useState({
        name: "",
        contactPerson: "",
        phoneNumber: "",
        address: "",
        email: "",
        gstin: "",
        website: "",
        isTrial: false,
        maxUserLimit: 0
    });

    const addOrgMutation = useMutation(async (data) => {
        let resp = await axios.post(`${BACKEND_URL}/auth/v1/admin/createOrganisation`, data, {
            headers: {
                Authorization: `Bearer ${window.localStorage.getItem("sauron-goat-token")}`
            }

        });
        return resp.data;
    }, {
        onSuccess: (data) => {
            if (data.status) {

                
                props.refetchOrgs()
                props.close();

                alert(`Organization added successfully \n\nId: ${data?.data?.user?.userName} \nPassword: ${data?.data?.user?.password}`)
            }
            else {
                alert(data.message);
            }
        },
        onError: (error) => {
            alert(error.message);
        }
    });

    const handleAddOrg = () => {

        if (!orgData.name || !orgData.contactPerson || !orgData.phoneNumber || !orgData.address) {
            alert("Please fill all required fields");
            return;
        }

        let data = {
            name: orgData.name,
            contactPerson: orgData.contactPerson,
            phoneNumber: parseInt(orgData.phoneNumber),
            address: orgData.address,
            isTrial: orgData.isTrial,
            maxUserLimit: orgData.maxUserLimit ? parseInt(orgData.maxUserLimit) : 0
        }

        if (orgData.email) data.email = orgData.email;
        if (orgData.gstin) data.gstin = orgData.gstin;
        if (orgData.website) data.website = orgData.website;

        addOrgMutation.mutate(data);
    }

    return (
        <form onSubmit={(e) => {
            console.log("entered")
            e.preventDefault();
            handleAddOrg();
        }}>
            <Dialog open={props.open} onClose={props.close} fullScreen>
                <DialogTitle><h1>Add Organization</h1></DialogTitle>

                {(addOrgMutation.isLoading) && <div style={{ padding: 16 }}>
                    <TopLoader backgroundColor="#eee6ff" show fixed={true} color="#ea6a12" />
                </div>}

                <DialogContent className="mt-2 px-3 d-flex flex-column justify-content-even align-items-center" sx={{ columnGap: "1rem", rowGap: "2rem" }}>

                    <div className="d-flex flex-md-row flex-lg-row flex-column col-12 justify-content-around align-items-center" style={{ rowGap: "1rem" }}>
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" required label="Name" placeholder="Enter name" value={orgData.name} onChange={(e) => setOrgData({ ...orgData, name: e.target.value })} />
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" required label="Contact Person" placeholder="Enter contact person" value={orgData.contactPerson} onChange={(e) => setOrgData({ ...orgData, contactPerson: e.target.value })} />
                    </div>

                    <div className="d-flex flex-md-row flex-lg-row flex-column col-12 justify-content-around align-items-center" style={{ rowGap: "1rem" }}>
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" required label="Phone Number" placeholder="Enter phone number" value={orgData.phoneNumber} onChange={(e) => setOrgData({ ...orgData, phoneNumber: e.target.value })} />
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" required label="Address" placeholder="Enter address" value={orgData.address} onChange={(e) => setOrgData({ ...orgData, address: e.target.value })} />
                    </div>

                    <div className="d-flex flex-md-row flex-lg-row flex-column col-12 justify-content-around align-items-center" style={{ rowGap: "1rem" }}>
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" label="Email" placeholder="Enter email" value={orgData.email} onChange={(e) => setOrgData({ ...orgData, email: e.target.value })} />
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" label="GSTIN" placeholder="Enter gstin" value={orgData.gstin} onChange={(e) => setOrgData({ ...orgData, gstin: e.target.value })} />
                    </div>

                    <div className="d-flex flex-md-row flex-lg-row flex-column col-12 justify-content-around align-items-center">
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" label="Website" placeholder="Enter website" value={orgData.website} onChange={(e) => setOrgData({ ...orgData, website: e.target.value })} />

                        <TextField
                            select
                            SelectProps={{ native: true }}
                            label="Is Trial"
                            defaultValue={orgData.isTrial ? "Yes" : "No"}
                            className="col-lg-5 col-md-5 col-11"
                            onChange={(e) => setOrgData({ ...orgData, isTrial: e.target.value === "Yes" })}
                        >
                            <option key={1} value={"Yes"}>Yes</option>
                            <option key={2} value={"No"}>No</option>
                        </TextField>
                    </div>
                    <div className="d-flex flex-md-row flex-lg-row flex-column col-12 justify-content-around align-items-center">
                        <TextField variant="standard" className="col-lg-5 col-md-5 col-11" label="Max User Limit" type="number" placeholder="Enter max user limit" value={orgData.maxUserLimit} onChange={(e) => setOrgData({ ...orgData, maxUserLimit: e.target.value })} />
                        
                    </div>
                </DialogContent>

                <DialogActions>
                    <Button onClick={props.close} color="inherit" variant="contained">Cancel</Button>
                    <Button type="submit" variant="contained" color="primary" onClick={handleAddOrg}>Add</Button>
                </DialogActions>

            </Dialog>
        </form>
    )
}