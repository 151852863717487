import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import { useMutation } from "react-query";
import axios from "axios";
import { useState } from "react";
import { BACKEND_URL } from "../constants/urls";
import TopLoader from "react-top-loader";

export default function LoginDialog(props) {

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");

    const loginMutation = useMutation(async (data) => {
        let resp = await axios.post(`${BACKEND_URL}/auth/v1/admin/login`, data);
        return resp.data;
    },
        {
            onSuccess: (data) => {
                if (data.status) {
                    window.localStorage.setItem("sauron-goat-token", data.data.token);
                    props.close()
                }
                else {
                    alert(data.message);
                }
            },

            onError: (error) => {
                alert(error.message);
            }
        });

    const handleLogin = (e) => {
        e.preventDefault();

        let data = {
            phoneNumber: parseInt(username) || 0,
            otp: password
        }

        loginMutation.mutate(data);
    }

    return <Dialog open={props.open} onClose={props.close}>
        <DialogTitle>Login </DialogTitle>

        <DialogContent >

            {loginMutation.isLoading && <div style={{ padding: 16 }}>
                <TopLoader backgroundColor="#eee6ff" show fixed={true} color="#ea6a12" />
            </div>}

            <form className="d-flex flex-column py-3 px-2">
                <TextField required value={username} onChange={(e) => setUsername(e.target.value)} label="Username" placeholder={"Enter username"} />
                <TextField className="mt-3" required value={password} onChange={(e) => setPassword(e.target.value)} label="Password" placeholder={"Enter password"} type="password" />
                <Button className="mt-3" type="submit" variant="contained" color="primary" onClick={handleLogin}>Login</Button>
            </form>
        </DialogContent>
    </Dialog>

}